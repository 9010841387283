<template>
  <div>
    <template v-if="selectedFlag">
      <div class="cursor-pointer vs-con-loading__container" id="confirm-btn-loading">
        <div class="flex items-center bg-primary font-semibold text-sm gap-1 shadow text-white rounded px-4 py-2 h-btn" @click="requestPickup()">
          <feather-icon icon="TruckIcon" svgClasses="w-4 h-4" class="self-center"/>
          <span class="text-sm ml-2">{{confirmReturn ? $t('confirm to return') : $t('Confirm to Ship')}}</span>
        </div>
      </div>
    </template>
    <template v-else>
      <vx-tooltip position="bottom" color="warning" :text="`${$t('Please select the order you want to ship!')}`">
        <div class="cursor-not-allowed">
          <div class="pointer-events-none bg-cold-morning text-silver rounded flex items-center px-4 py-2 gap-1 h-btn">
            <feather-icon icon="TruckIcon" svgClasses="w-4 h-4" class="self-center"/>
            <span class="text-sm ml-2">{{confirmReturn ? $t('confirm to return') : $t('Confirm to Ship')}} </span>
          </div>
        </div>
      </vx-tooltip>
    </template>
  </div>
</template>

<script>
import { sendFulfillmentRequest } from '../../../../http/axios/requestHelper.js'
export default {
  props: ['selected', 'confirmReturn'],
  data () {
    return {
      selectedFlag: false
    }
  },
  watch: {
    selected (val) {
      if (val.length !== 0 && val.filter(item => item.status === 'created' || item.status === 'confirmed_to_return').length !== 0) {
        this.selectedFlag = true
      } else {
        this.selectedFlag = false
      }
    }
  },
  methods: {
    requestPickup () {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#confirm-btn-loading',
        scale: 0.45
      })
      const trackings = []
      let url = ''
      this.selected.forEach(element => {
        if (this.$route.name === 'merchant-fulfillment-outbound-orders' && (element.status === 'created' || element.status === 'confirmed_to_return')) {
          url = `api/v1/fc/orders/${this.selected[0].id}/tracking/`
          trackings.push({
            order:element.id,
            status: 'confirmed_to_ship'
          })
        } else if (this.$route.name === 'merchant-fulfillment-inbound-orders') {
          url = `api/v1/fc/orders/${this.selected[0].id}/tracking/`
          trackings.push({
            order:element.id,
            status: 'confirmed_to_return'
          })
        }
      })
      sendFulfillmentRequest(true, false, this, url, 'post', trackings, true,
        () => {
          this.$emit('loadShipments')
          this.$emit('selected', [])
          this.$vs.loading.close('#confirm-btn-loading > .con-vs-loading')
        }
      )
    }
  }
}
</script>