<template>
  <div>
    <div class="cursor-pointer">
      <div class="flex items-center bg-primary font-semibold text-sm gap-1 shadow text-white rounded px-4 py-2 h-btn" @click="openExportModal()">
        <feather-icon icon="DownloadIcon" svgClasses="w-4 h-4" class="self-center"/>
        <span class="text-sm ml-2">{{ $t('Export') }}</span>
      </div>
    </div>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeExportModal"
      :active.sync="exportModal"
      :title="$t('Export')"
      :buttons-hidden="true">
      <div class="text-center mt-1">
        <div class="mb-4">
          <p class="text-darkgray text-base mb-4">{{ $t('Select orders statuses you want to export!') }}</p>
          <div class="clearfix flex justify-center">
            <div v-for="(criteria, index) in criterias" :key="index">
              <label @click="checkCriteria(criteria)" class="prompt cursor-pointer whitespace-nowrap">
                <input v-model="criteriaName" :class="criteria.checked ? 'checked' : ''" :value="criteria.value" type="radio" />
                <span class="py-2 px-4 rounded mx-1">{{ criteria.name }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-4">
          <button @click="closeExportModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
          <button @click="sendExport" :class="criteriaName !== '' ? 'active-btn' : 'disable-btn pointer-events-none'" class="btn">{{ $t('Export') }}</button>
        </div>
      </div>
    </shipblu-prompt>
  </div>
</template>
  
<script>
import { sendFulfillmentRequest } from '../../../../http/axios/requestHelper'
import i18nData from '../../../../i18n/i18nData'
import ShipbluPrompt from '../../../../layouts/components/ShipBluPrompt.vue'

export default {
  data () {
    return {
      criterias: [
        {
          name: 'In Progress',
          value: 'in progress',
          checked: false
        },
        {
          name: 'Shipped',
          value: 'shipped',
          checked: false
        }
      ],
      exportModal: false,
      criteriaName: ''
    }
  },
  methods: {
    checkCriteria (criteria) {
      this.criterias.forEach(item => {
        if (item.name === criteria.name) {
          item.checked = true
        } else {
          item.checked = false
        }
      })
    },
    sendExport () {
      sendFulfillmentRequest(false, false, this, 'api/v1/fc/orders/outbound/export/', 'post', {status: this.criteriaName}, true, 
        (response) => {
          this.$vs.notify({
            color: 'success',
            title: i18nData[this.$i18n.locale]['Success'],
            text: response.data.message,
            position: 'top-center'
          })
          this.closeExportModal()
        }
      )
    },
    closeExportModal () {
      this.exportModal = false
      this.criteriaName = ''
      this.criterias.forEach(item => {
        item.checked = false
      })
    },
    openExportModal () {
      this.exportModal = true
    }
  },
  components: {
    ShipbluPrompt
  }
}
</script>